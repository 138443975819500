import React from "react"
import OffersHelper from "../helpers/offers"

import img_service_cover_bg from "../images/top-bg.png"
import img_aws_service_cover_bg from "../images/aws-bg.png"
import img_a1_cloud_small from "../images/small-logo.png"
import img_aws_small from "../images/aws-small-logo.png"

export default function ServiceListBoxCard(props) {

    // First service
    let firstServiceClass=""
    if (props.isFirst)
        firstServiceClass = " first-box"

    // New service
    let newServiceClass=""
    if (props.isNew)
        newServiceClass = " budget_div"

    // Type of card
    let bg_img = img_service_cover_bg;
    let small_logo_img = img_a1_cloud_small;
    switch (props.type){
      case 'aws':
        bg_img = img_aws_service_cover_bg;
        small_logo_img = img_aws_small;
        break;
    }

    let linkTarget = "_top";
    // URL target based on the type of link
    switch (props.link_type){
      case "external":
        linkTarget = "_blank";
        break;
    }
    
    const offerColorsObj = OffersHelper.getOfferCardColors(props.category)

    return (
        <>
            <div className={`service-page-box-row${firstServiceClass}${newServiceClass} cat_${props.category}`}>
            <div className="service-page-box-row-img">
              <a href={props.link_target_url} title={props.link_seo_title} target={linkTarget}>
                <div className="service-page-box-row-img-first">
                  <img
                    className="bg_img"
                    src={bg_img}
                    alt="top-bg"
                  />
                  <div className="first-img-text">
                    <img src={offerColorsObj.icon} alt="imh-icon" />
                    <span>
                      <small style={offerColorsObj.style}>{props.category_label}:</small>
                      <br />
                      <b>{props.service_name_fragment_1}</b>
                      <br />
                      {props.service_name_fragment_2}
                      {props.service_name_fragment_3 ? <br /> : ""}
                      {props.service_name_fragment_3}
                      {props.service_name_fragment_4 ? <br /> : ""}
                      {props.service_name_fragment_4}
                      {props.service_name_fragment_5 ? <br /> : ""}
                      {props.service_name_fragment_5}
                    </span>
                  </div>
                </div>
                <div className="service-page-box-row-img-secound">
                  <img
                    className="bg_img"
                    src={offerColorsObj.bottom}
                    alt="service01"
                  />
                  <div className="first-img-text">
                    <img src={small_logo_img} alt="small-logo" />
                  </div>
                </div>
              </a>
            </div>
            <div className="service-page-box-row-text">
              <h4 style={offerColorsObj.style}>{props.category_label}</h4>
              <h3>
                <a href={props.link_target_url} title={props.link_seo_title} target={linkTarget}>
                {props.service_name}
                </a>
              </h3>
              <p>
                {props.service_description}
              </p>
            </div>
            <a href={props.link_target_url} className="btn" title={props.link_seo_title}  target={linkTarget}>
            {props.link_seo_btn}
            </a>
          </div>
        </>
  )
}