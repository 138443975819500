import * as React from "react"
import {useState, useEffect} from "react"
import { Helmet } from "react-helmet"
import Slider from "react-slick";
import i18n from '../components/i18n'
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import Layout from "../components/layout"
import ServiceListBoxCard from "../components/service_list_box_card"
import NavigationHelper from "../helpers/navigation"
import Seo from '../components/seo'

/** Images import */
import img_banner from "../images/a1_cloud_services_desktop_banner_bg.webp"
import img_books from "../images/a1_cloud_services_in_a_box.webp"
import img_video_javelo from "../images/javelo_a1_cloud_testimonial_preview.webp"
import img_tpop_arrow_icon from "../images/a1_cloud_blue_quote_sign.webp"
import img_photo_man from "../images/thumb_video_upfactor.webp"
import img_thumb_javelo from "../images/thumb_video_javelo.webp"
import img_video_img from "../images/upfactor_a1_cloud_testimonial_preview.webp"


// markup
const ServicesPage = (props) => {
  
  const [activeLanguage, setActiveLanguage] = useState()
  useEffect(() => { setActiveLanguage(i18n.language); }, [setActiveLanguage])

  const { t } = useTranslation(['main', 'forms']);

  const ExtServiceCard = withTranslation('main')(ServiceListBoxCard)

  // Navigation info
  const pageInfo = {
    key: "services",
    lang: i18n.language
  }

  const mobile_offers_slider_settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false
  };

  const testimonials_slider_settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000
  };

  return (
    <Layout
      location={props.location}
      pageInfo={pageInfo}
    >
      <Helmet
        bodyAttributes={{
          class: 'nos-service-page'
      }}
      >
      </Helmet>

      <Seo
        description={t('main:services_meta_seo-description_txt')}
        title={t('main:services_meta_seo-title_txt' )}
        keywords={[
          t('main:services_meta_seo-keyword1_txt'),
          t('main:services_meta_seo-keyword2_txt'),
          t('main:services_meta_seo-keyword3_txt'),
          t('main:services_meta_seo-keyword4_txt'),
          t('main:services_meta_seo-keyword5_txt'),
          t('main:services_meta_seo-keyword6_txt'),
          t('main:services_meta_seo-keyword7_txt')
        ]}
        url={props.location.href}
        image_url={`${props.location.origin}/a1_cloud_services_in_a_box.webp`}
      />

    {/* breadcumbs */}
    <div className="site-breadcumbs">
      <div className="container">
        <small>
          <a href={NavigationHelper.useGeneratePageURL(activeLanguage, 'home')} title={t('main:global_header_home_menu-item-seo-title_txt')}>
            A1:
          </a>{" "}
          // {t('main:services_header_breadcrumb_title')}
        </small>
      </div>
    </div>
    {/* end breadcumbs */}

    <div className="slider-banner-section inner-page-main-cont sarvice-page">
    <div className="container-fluid">
      <div className="row">
        <div className="slider-banner-img">
          <img className="banner_bg" src={img_banner} alt="banner" />
          <div className="banner-contan">
            <h1 className="nos-service">{t('main:services_main_header_title')}</h1>
            <div className="text_img row">
              <div className="col-md-5">
                <img className="book_img" src={img_books} alt="Books" />
              </div>
              <div className="col-md-7">
                {/* for desktop only */}
                <div className="text-content ipade_up">
                  <span>
                  <Trans i18nKey="main:services_header_block-title_html">
                    <strong>Renforcez vos équipes</strong><strong>avec des expertises Cloud</strong><strong>"out of the box"</strong>
                  </Trans>
                  </span>
                  <div className="text-content-banner-text">
                    <p>
                      <Trans i18nKey="main:services_header_block_p1_html">
                      Quel que soit le domaine (<i>architecture, SRE, Cloud, conteneurs,...</i>) l'intégration dans vos équipes de nouvelles ressources vous permet de vous concentrer sur l'essentiel: apporter de la valeur à votre clientèle et à votre business.<br/>
                      </Trans>
                      <Trans i18nKey="main:services_header_block_p2_html">
                      <br/>Sélectionnez la ou les thématiques sur lesquelles <strong>A1 Cloud</strong> peut vous appuyer en priorité
                      </Trans>
                    </p>
                  </div>
                </div>
                {/* for ipade only */}
                <div className="text-content desktop_view ipade_down">
                  <span>
                    <Trans i18nKey="main:services_header_block-title_html">
                      <strong>Renforcez vos équipes</strong><strong>avec des expertises Cloud</strong><strong>"out of the box"</strong>
                    </Trans>
                  </span>
                  <div className="text-content-banner-text">
                      <p>
                      <Trans i18nKey="main:services_header_block_p1_html">
                      Quel que soit le domaine (<i>architecture, SRE, Cloud, conteneurs,...</i>) l'intégration dans vos équipes de nouvelles ressources vous permet de vous concentrer sur l'essentiel: apporter de la valeur à votre clientèle et à votre business.<br/>
                      </Trans>
                      <Trans i18nKey="main:services_header_block_p2_html">
                      <br/>Sélectionnez la ou les thématiques sur lesquelles <strong>A1 Cloud</strong> peut vous appuyer en priorité
                      </Trans>
                      </p>
                  </div>
                </div>
                {/* for mobile only */}
                <div className="text-content mobile_view">
                  <span>
                    <Trans i18nKey="main:services_header_block-title_html">
                      <strong>Renforcez vos équipes</strong><strong>avec des expertises Cloud</strong><strong>"out of the box"</strong>
                    </Trans>
                  </span>
                  <div className="text-content-banner-text">
                      <p>
                      <Trans i18nKey="main:services_header_block_p1_html">
                      Quel que soit le domaine (<i>architecture, SRE, Cloud, conteneurs,...</i>) l'intégration dans vos équipes de nouvelles ressources vous permet de vous concentrer sur l'essentiel: apporter de la valeur à votre clientèle et à votre business.<br/>
                      </Trans>
                      <Trans i18nKey="main:services_header_block_p2_html">
                      <br/>Sélectionnez la ou les thématiques sur lesquelles <strong>A1 Cloud</strong> peut vous appuyer en priorité
                      </Trans>
                      </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="service-page-main-contant">
    <div className="container">
      <div className="row new-mobile-slider desktop_view justify-content-center">
        <div className="col-xl-3 col-lg-4 col-md-4 col-12">
          <ExtServiceCard
            isFirst={true}
            isNew={false}
            category="devops"
            category_label={t('main:services_main_devops-category_title' )}
            service_name={t('main:services_main_release-mgt-offer_title' )}
            service_name_fragment_1={t('main:services_main_release-mgt-offer_p1')}
            service_name_fragment_2={t('main:services_main_release-mgt-offer_p2')}
            link_seo_title={`${t('main:global_main_know-more-on-offer_link')} ${t('main:services_main_release-mgt-offer_title')}`}
            link_seo_btn={t('main:global_main_know-more_link')}
            link_target_url={NavigationHelper.useGeneratePageURL(activeLanguage, 'solutions', 'release-engineering')}
            service_description={t('main:service-description_release-engin_left-block_desc_txt')}
          />
        </div>
        {/* <div className="col-xl-3 col-lg-4 col-md-4 col-12">
        <ExtServiceCard
            isFirst={false}
            isNew={false}
            category="devops"
            category_label={t('main:services_main_devops-category_title' )}
            service_name={t('main:services_main_pipeline-offer_title')}
            service_name_fragment_1={t('main:services_main_pipeline-offer_p1')}
            service_name_fragment_2={t('main:services_main_pipeline-offer_p2')}
            link_seo_title={t('main:global_main_know-more_link')}
            link_seo_btn={t('main:global_main_know-more_link')}
            link_target_url="#newservice"
            service_description="
            Industrialisez et automatisez votre infrastructure de livraison
            pour rester concentré sur la création de valeur tout en
            réduisant les risques inhérents aux déploiements.
            "
          />
        </div> */}
        {/* <div className="col-xl-3 col-lg-4 col-md-4 col-12">
        <ExtServiceCard
            isFirst={false}
            isNew={false}
            category="review"
            category_label={t('main:services_main_review-category_title')}
            service_name={t('main:services_main_finops-audit-offer_title')}
            service_name_fragment_1={t('main:services_main_finops-audit-offer_p1')}
            service_name_fragment_2={t('main:services_main_finops-audit-offer_p2')}
            service_name_fragment_3={t('main:services_main_finops-audit-offer_p3')}
            service_name_fragment_4={t('main:services_main_finops-audit-offer_p4')}
            link_seo_title={t('main:global_main_know-more_link')}
            link_seo_btn={t('main:global_main_know-more_link')}
            link_target_url="#newservice3"
            service_description="
            Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
            auctor. Vestibulum id ligula porta felis…
            "
          />
        </div> */}
        <div className="col-xl-3 col-lg-4 col-md-4 col-12">
        <ExtServiceCard
            isFirst={false}
            isNew={false}
            category="cloud"
            category_label={t('main:services_main_cloud-category_title' )}
            service_name={t('main:services_main_migration-offer_title')}
            service_name_fragment_1={t('main:services_main_migration-offer_p1')}
            service_name_fragment_2={t('main:services_main_migration-offer_p2')}
            link_seo_title={`${t('main:global_main_know-more-on-offer_link')} ${t('main:services_main_migration-offer_title')}`}
            link_seo_btn={t('main:global_main_know-more_link')}
            link_target_url={NavigationHelper.useGeneratePageURL(activeLanguage, 'solutions', 'cloud-migration')}
            service_description={t('main:service-description_cloud-migration_left-block_desc_txt')}
          />
        </div>
        {/* <div className="col-xl-3 col-lg-4 col-md-4 col-12">
        <ExtServiceCard
            isFirst={false}
            isNew={false}
            category="review"
            category_label={t('main:services_main_review-category_title' )}
            service_name={t('main:services_main_security-audit-offer_title')}
            service_name_fragment_1={t('main:services_main_security-audit-offer_p1')}
            service_name_fragment_2={t('main:services_main_security-audit-offer_p2')}
            service_name_fragment_3={t('main:services_main_security-audit-offer_p3')}
            service_name_fragment_4={t('main:services_main_security-audit-offer_p4')}
            link_seo_title={t('main:global_main_know-more_link')}
            link_seo_btn={t('main:global_main_know-more_link')}
            link_target_url="#newservice"
            service_description="
            Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
            auctor. Vestibulum id ligula porta felis euismod semper. Nulla
            vitae elit libero, a pharetra augue.
            "
          />
        </div> */}
        {/* <div className="col-xl-3 col-lg-4 col-md-4 col-12">
        <ExtServiceCard
            isFirst={false}
            isNew={false}
            category="review"
            category_label={t('main:services_main_review-category_title' )}
            service_name={t('main:services_main_orga-audit-offer_title')}
            service_name_fragment_1={t('main:services_main_orga-audit-offer_p1')}
            service_name_fragment_2={t('main:services_main_orga-audit-offer_p2')}
            service_name_fragment_3={t('main:services_main_orga-audit-offer_p3')}
            service_name_fragment_4={t('main:services_main_orga-audit-offer_p4')}
            service_name_fragment_5={t('main:services_main_orga-audit-offer_p5')}
            link_seo_title={t('main:global_main_know-more_link')}
            link_seo_btn={t('main:global_main_know-more_link')}
            link_target_url="#newservice"
            service_description="
            Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
            auctor. Vestibulum id ligula porta felis euismod semper. Nulla
            vitae elit libero, a pharetra augue.
            "
          />
        </div> */}
        <div className="col-xl-3 col-lg-4 col-md-4 col-12">
        <ExtServiceCard
            isFirst={false}
            isNew={false}
            category="sre"
            category_label={t('main:services_main_sre-category_title' )}
            service_name={t('main:services_main_sre-aws-offer_title')}
            service_name_fragment_1={t('main:services_main_sre-aws-offer_p1')}
            service_name_fragment_2={t('main:services_main_sre-aws-offer_p2')}
            service_name_fragment_3={t('main:services_main_sre-aws-offer_p3')}
            service_name_fragment_4={t('main:services_main_sre-aws-offer_p4')}
            link_seo_title={`${t('main:global_main_know-more-on-offer_link')} ${t('main:services_main_sre-aws-offer_title')}`}
            link_seo_btn={t('main:global_main_know-more_link')}
            link_target_url={NavigationHelper.useGeneratePageURL(activeLanguage, 'solutions', 'run-aws')}
            service_description={t('main:service-description_run-aws_left-block_desc_txt')}
          />
        </div>
      </div>
      <div className="new-mobile-slider mobile_view mobile_slider">
      <Slider {...mobile_offers_slider_settings}>
        <div className="col-12">
        <ExtServiceCard
            isFirst={true}
            isNew={false}
            category="devops"
            category_label={t('main:services_main_devops-category_title' )}
            service_name={t('main:services_main_release-mgt-offer_title' )}
            service_name_fragment_1={t('main:services_main_release-mgt-offer_p1')}
            service_name_fragment_2={t('main:services_main_release-mgt-offer_p2')}
            link_seo_title={`${t('main:global_main_know-more-on-offer_link')} ${t('main:services_main_release-mgt-offer_title')}`}
            link_seo_btn={t('main:global_main_know-more_link')}
            link_target_url={NavigationHelper.useGeneratePageURL(activeLanguage, 'solutions', 'release-engineering')}
            service_description={t('main:service-description_release-engin_left-block_desc_txt')}
          />
        </div>
        {/* <div className="col-12">
        <ExtServiceCard
            isFirst={false}
            isNew={false}
            category="devops"
            category_label={t('main:services_main_devops-category_title' )}
            service_name={t('main:services_main_pipeline-offer_title')}
            service_name_fragment_1={t('main:services_main_pipeline-offer_p1')}
            service_name_fragment_2={t('main:services_main_pipeline-offer_p2')}
            link_seo_title={t('main:global_main_know-more_link')}
            link_seo_btn={t('main:global_main_know-more_link')}
            link_target_url="#newservice"
            service_description="
            Industrialisez et automatisez votre infrastructure de livraison
            pour rester concentré sur la création de valeur tout en
            réduisant les risques inhérents aux déploiements.
            "
          />
        </div> */}
        {/* <div className="col-12">
        <ExtServiceCard
            isFirst={false}
            isNew={false}
            category="review"
            category_label={t('main:services_main_review-category_title')}
            service_name={t('main:services_main_finops-audit-offer_title')}
            service_name_fragment_1={t('main:services_main_finops-audit-offer_p1')}
            service_name_fragment_2={t('main:services_main_finops-audit-offer_p2')}
            service_name_fragment_3={t('main:services_main_finops-audit-offer_p3')}
            service_name_fragment_4={t('main:services_main_finops-audit-offer_p4')}
            link_seo_title={t('main:global_main_know-more_link')}
            link_seo_btn={t('main:global_main_know-more_link')}
            link_target_url="#newservice3"
            service_description="
            Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
            auctor. Vestibulum id ligula porta felis…
            "
          />
        </div> */}
        <div className="col-12">
        <ExtServiceCard
            isFirst={false}
            isNew={false}
            category="cloud"
            category_label={t('main:services_main_cloud-category_title' )}
            service_name={t('main:services_main_migration-offer_title')}
            service_name_fragment_1={t('main:services_main_migration-offer_p1')}
            service_name_fragment_2={t('main:services_main_migration-offer_p2')}
            link_seo_title={`${t('main:global_main_know-more-on-offer_link')} ${t('main:services_main_migration-offer_title')}`}
            link_seo_btn={t('main:global_main_know-more_link')}
            link_target_url={NavigationHelper.useGeneratePageURL(activeLanguage, 'solutions', 'cloud-migration')}
            service_description={t('main:service-description_cloud-migration_left-block_desc_txt')}
          />
        </div>
        {/* <div className="col-12">
        <ExtServiceCard
            isFirst={false}
            isNew={false}
            category="review"
            category_label={t('main:services_main_review-category_title' )}
            service_name={t('main:services_main_security-audit-offer_title')}
            service_name_fragment_1={t('main:services_main_security-audit-offer_p1')}
            service_name_fragment_2={t('main:services_main_security-audit-offer_p2')}
            service_name_fragment_3={t('main:services_main_security-audit-offer_p3')}
            service_name_fragment_4={t('main:services_main_security-audit-offer_p4')}
            link_seo_title={t('main:global_main_know-more_link')}
            link_seo_btn={t('main:global_main_know-more_link')}
            link_target_url="#newservice"
            service_description="
            Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
            auctor. Vestibulum id ligula porta felis euismod semper. Nulla
            vitae elit libero, a pharetra augue.
            "
          />
        </div> */}
        {/* <div className="col-12">
        <ExtServiceCard
            isFirst={false}
            isNew={false}
            category="review"
            category_label={t('main:services_main_review-category_title' )}
            service_name={t('main:services_main_orga-audit-offer_title')}
            service_name_fragment_1={t('main:services_main_orga-audit-offer_p1')}
            service_name_fragment_2={t('main:services_main_orga-audit-offer_p2')}
            service_name_fragment_3={t('main:services_main_orga-audit-offer_p3')}
            service_name_fragment_4={t('main:services_main_orga-audit-offer_p4')}
            service_name_fragment_5={t('main:services_main_orga-audit-offer_p5')}
            link_seo_title={t('main:global_main_know-more_link')}
            link_seo_btn={t('main:global_main_know-more_link')}
            link_target_url="#newservice"
            service_description="
            Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
            auctor. Vestibulum id ligula porta felis euismod semper. Nulla
            vitae elit libero, a pharetra augue.
            "
          />
        </div> */}
        <div className="col-12">
        <ExtServiceCard
            isFirst={false}
            isNew={false}
            category="sre"
            category_label={t('main:services_main_sre-category_title' )}
            service_name={t('main:services_main_sre-aws-offer_title')}
            service_name_fragment_1={t('main:services_main_sre-aws-offer_p1')}
            service_name_fragment_2={t('main:services_main_sre-aws-offer_p2')}
            service_name_fragment_3={t('main:services_main_sre-aws-offer_p3')}
            service_name_fragment_4={t('main:services_main_sre-aws-offer_p4')}
            link_seo_title={`${t('main:global_main_know-more-on-offer_link')} ${t('main:services_main_sre-aws-offer_title')}`}
            link_seo_btn={t('main:global_main_know-more_link')}
            link_target_url={NavigationHelper.useGeneratePageURL(activeLanguage, 'solutions', 'run-aws')}
            service_description={t('main:service-description_run-aws_left-block_desc_txt')}
          />
        </div>
      </Slider>
      </div>
    </div>
  </div>
  {/* testimonial section  */}
  <div className="service-page testimonial_sec">
  <div className="container">
      <h1 className="sec-title"> {t('main:title_your_testimonials' )} </h1>
      <div className="testi-slider">
      <div className="home-testimonial-slider responsive slick-initialized slick-slider slick-dotted">
      <Slider {...testimonials_slider_settings}>
      <div className="slider-block">
        <div className="img-box">
          <div className="res_img">
            <a href={NavigationHelper.useGeneratePageURL(activeLanguage, 'client_testimonial', "upfactor")} title={t('main:single-testimonial_see-video-seo-link_txt', { clientname: "UpFactor" })}>
            <img src={img_video_img} alt="Vidéo-image" />
            <div className="play-icon">
              <i className="fa fa-play" aria-hidden="true" />
            </div>
            </a>
          </div>
        </div>
        <div className="text-block">
          <div className="text-block-img">
            <span className="quot_img">
              <img src={img_tpop_arrow_icon} />
            </span>
            <div className="user_img">
              <img
                className="thumbnail_img"
                src={img_photo_man}
                alt={t('main:global_person_photo-alt_txt', {name: "Didier Mignery"})}
              />
            </div>
          </div>
          <div className="text-block-right-text">
            <h2 className="desktop_view">
              <q>
                {t('home_testimonials_upfactor_p1')}
                <br /> {t('main:home_testimonials_upfactor_p2')}
                <br /> {t('main:home_testimonials_upfactor_p3')}
              </q>
            </h2>
            <h2 className="mobile_view">
              <q>
                {t('home_testimonials_upfactor_p1' )}
                <br /> {t('main:home_testimonials_upfactor_p2')}
                <br /> {t('main:home_testimonials_upfactor_p3')}
                {/* <br /> des bâtiments… */}
              </q>
            </h2>
            <div className="user_designation">
              <h5>{t('main:single-testimonial_main_upfactor-interviewee-name_txt')}</h5>
              <span>{t('single-testimonial_main_upfactor-interviewee-position_txt')}</span>
            </div>
            <a href={NavigationHelper.useGeneratePageURL(activeLanguage, 'client_testimonial', "upfactor")} title={t('main:single-testimonial_see-video-seo-link_txt', { clientname: "UpFactor" })}>
              {t('main:global_testimonials_see_btn')}
            </a>
          </div>
        </div>
      </div>
      <div className="slider-block">
        <div className="img-box">
          <div className="res_img">
          <a href={NavigationHelper.useGeneratePageURL(activeLanguage, 'client_testimonial', "javelo")} title={t('main:single-testimonial_see-video-seo-link_txt', { clientname: "Javelo" })}>
            <img src={img_video_javelo} alt="Vidéo-image" />
            <div className="play-icon">
              <i className="fa fa-play" aria-hidden="true" />
            </div>
            </a>
          </div>
        </div>
        <div className="text-block">
          <div className="text-block-img">
            <span className="quot_img">
              <img src={img_tpop_arrow_icon} />
            </span>
            <div className="user_img">
              <img
                className="thumbnail_img"
                src={img_thumb_javelo}
                alt={t('main:global_person_photo-alt_txt', {name: "Bakari Sumaila"})}
              />
            </div>
          </div>
          <div className="text-block-right-text">
            <h2 className="desktop_view">
              <q>
              {t('main:home_testimonials_javelo_p1')} <br />
              {t('main:home_testimonials_javelo_p2')} <br />
              {t('main:home_testimonials_javelo_p3')} <br />
              {t('main:home_testimonials_javelo_p4')} <br />
              {t('main:home_testimonials_javelo_p5')}
              </q>
            </h2>
            <h2 className="mobile_view">
              <q>
              {t('main:home_testimonials_javelo_p1')} <br />
              {t('main:home_testimonials_javelo_p2')} <br />
              {t('main:home_testimonials_javelo_p3')} <br />
              {t('main:home_testimonials_javelo_p4')} <br />
              {t('main:home_testimonials_javelo_p5')}
              </q>
            </h2>
            <div className="user_designation">
              <h5>{t('main:single-testimonial_main_javelo-interviewee-name_txt')}</h5>
              <span>{t('main:single-testimonial_main_javelo-interviewee-position_txt')}</span>
            </div>
            <a href={NavigationHelper.useGeneratePageURL(activeLanguage, 'client_testimonial', "javelo")} title={t('main:single-testimonial_see-video-seo-link_txt', { clientname: "Javelo" })}>
              {t('main:global_testimonials_see_btn')}
            </a>
          </div>
        </div>
      </div>
      </Slider>
      </div>
        <div className="text-center">
          <a href={NavigationHelper.useGeneratePageURL(activeLanguage, 'testimonials')} className="btn btn-blue" title={t('main:global_testimonials_see-all-seo-title_txt')}>
            {t('main:global_testimonials_see-all_btn')}
          </a>
        </div>
      </div>
    </div>
  </div>
  {/* main content end */}
    </Layout>
  )
}

export default ServicesPage
